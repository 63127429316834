@import "mixins";

body {
  font-family: var(--sans-serif-secondary);
  line-height: 1.4;
  font-size: var(--text-scale-6);
  
  @include for-phone {
    font-size: var(--text-scale-5);
  }
}

h1 {
  font-family: var(--sans-serif-primary);
  font-size: var(--text-scale-10);
  line-height: 1.2;
  
  @include for-tablet-portrait {
    font-size: var(--text-scale-10);
  }
  
  @include for-phone {
    font-size: var(--text-scale-9);
  }
}

h2 {
  font-family: var(--sans-serif-primary);
  font-size: var(--text-scale-9);
  line-height: 1.2;
  
  @include for-tablet-portrait {
    font-size: var(--text-scale-8);
  }
  
  @include for-phone {
    font-size: var(--text-scale-7);
  }
}

h3 {
  font-family: var(--sans-serif-primary);
  font-size: var(--text-scale-8);
  line-height: 1.2;
  
  @include for-tablet-portrait {
    font-size: var(--text-scale-7);
  }
  
  @include for-phone {
    font-size: var(--text-scale-6);
  }
}

h4 {
  font-family: var(--sans-serif-primary);
  font-size: var(--text-scale-7);
  line-height: 1.2;
      
  @include for-phone {
    font-size: var(--text-scale-6);
  }
}