.btn {
  background: var(--brand-green);
  color: white;
  padding: var(--sizing-scale-3) var(--sizing-scale-4);
  border-radius: 10px;
  font-weight: 600;
  
  &:hover {
    background: var(--brand-purple);
    color: white;
  }
}