@import "mixins";

.product-details {
  max-width: var(--sizing-scale-17);
  margin: auto;
  padding: var(--sizing-scale-3);
  margin-top: var(--sizing-scale-1);
  text-align: center;
  
  &__back {
    text-align: left;
    margin-top: var(--sizing-scale-4);
    margin-left: var(--sizing-scale-10);
    font-size: var(--text-scale-3);
    
    @include for-tablet-portrait {
      margin-left: var(--sizing-scale-4);
    }
    
    .icon {
      height: var(--sizing-scale-5);
      width: var(--sizing-scale-5);
      fill: var(--brand-red);
      float: left;
    }
    
    a:hover {
      .icon {
        fill: var(--brand-purple);
      }
    }
  }
  
  &__image img {
    height: var(--sizing-scale-14);
  }
  
  &__caption {
    font-weight: 600;
  }
  
  &__benefits {
    margin: auto;
    text-align: center;
    
    p {
      margin-left: calc(var(--sizing-scale-5) * -1);
      text-align: left;
      font-weight: 500;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
    
    ul {
      text-align: left;
      display: inline-block;
      max-width: 80%;
      margin: auto;
      margin-top: var(--sizing-scale-3);
      list-style-type: disc;
      font-size: var(--text-scale-5);
      line-height: 1.5;
      li {
        margin: var(--sizing-scale-2) auto;
      }
    }
  }
  
  &__capacity {
    font-size: var(--text-scale-5);

    em {
      font-weight: 700;
    }
  }
  
  &__data-sheet {
    text-align: right;
    font-size: var(--text-scale-5);
    font-weight: 600;
    
    .icon {
      width: var(--sizing-scale-4);
      height: var(--sizing-scale-4);
      fill: var(--brand-red);
      transform: translateY(2px);
      
      .background {
        fill: white;
      }
    }
    
    a:hover {
      .icon {
        fill: var(--brand-purple);
      }
    }
  }
}