.contact {
  iframe {
    width: 100%;
    height: var(--sizing-scale-14);
  }
  
  &__address {    
    p:first-child {
      font-size: var(--text-scale-7);
      font-weight: 700;
    }
  }
}