@import "mixins";

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
  padding-top: var(--header-height);
  
  @include for-phone {
    padding-top: var(--header-height-mobile);
  }
}