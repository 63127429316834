.dropdown {  
  &__checkbox {
    display: none;
  }
  
  label {
    user-select: none;
    display: block;
    width: 100%;
    font-size: 110%;
    font-weight: 400;
    margin-left: var(--sizing-scale-3);
    margin-top: var(--sizing-scale-2);
    margin-bottom: var(--sizing-scale-2);
  }
  
  li {
    margin-left: var(--sizing-scale-3);
    margin-top: var(--sizing-scale-2);
    margin-bottom: var(--sizing-scale-2);
    box-sizing: border-box;
    user-select: none;
    
    a {
      display: block;
      width: 100%;
    }
  }
  
  li.active {
    display: none;
  }
      
  ul {
    max-height: 0px;
    transition: max-height 200ms linear;
    overflow: hidden;
    border-top: 1px solid var(--gray-2);
  }
    
  label:after {
    content: url('../assets/icon-cheveron-down.svg');
    position: absolute;
    right: var(--sizing-scale-3);
    transition: transform 200ms linear 1ms;
    height: 32px;
    width: 32px;
  }
  
  &__checkbox:checked ~ ul  {
    max-height: 300px;
    border-bottom: 1px solid var(--yellow);
  }  
  
  &__checkbox:checked ~ label:after {
    transform: rotate(180deg);
  }
}
