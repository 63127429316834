@import "mixins";

.home {
  &__hero {
    background-color: black;
    color: white;
    text-align: center;
    padding-top: var(--sizing-scale-6);
    
    > .flex {
      height: 100%;
      align-items: center;
      justify-content: center;
      margin: auto;    
    }
    
    .hero {  
      &__callout {
        font-weight: 200;
      }
  
      &__photo img {
        max-height: var(--sizing-scale-14);
        max-width: 95vw;
      }
      
      &__tagline {
        font-family: var(--sans-serif-secondary);
        font-weight: 600;
        letter-spacing: 1.5px;
        color: var(--brand-orange);
        margin-top: var(--sizing-scale-6);
      }
      
      &__chevron {
        margin: auto;
        margin-top: var(--sizing-scale-6);
        margin-bottom: var(--sizing-scale-2);
        svg {
          height: var(--sizing-scale-8);
          fill: white;
        }
      }
    }
  }
  
  &__about {
    text-align: center;
    
    p {
      line-height: 1.4;
      max-width: var(--sizing-scale-18);
      margin: auto;
      padding: 0 var(--sizing-scale-2);
    }
  }
  
  &__featured {
    text-align: center;
    width: 80vw;
    margin: auto;  
    
    @include for-phone {
      width: 95vw;
    }
    
    h3 {
      font-weight: 600;
    }
    
    .flex {
      > * ~ * {
        margin-left: var(--sizing-scale-12);
      }
      
      @include for-tablet-portrait {
        > * ~ * {
          margin-top: var(--sizing-scale-4);
          margin-left: 0;
        }
      }
    }
    
    .featured {
      &__product {        
        h4 {
          font-weight: 500;
          text-decoration: underline;
          text-decoration-thickness: 3px;
        }   
      }
      
      &__image {
        height: var(--sizing-scale-14);
      }
            
      &__caption {
        font-weight: 700;
      }
      
      &__benefits {
        list-style-type: disc;
        list-style-position: inside;
        line-height: 1.5;
        text-align: left;
      }
      
      &__capacity {
        font-style: italic;
      }
      
      &__data-sheet {
        text-align: right;
        font-size: var(--text-scale-5);
        font-weight: 600;
        
        .icon {
          width: var(--sizing-scale-4);
          height: var(--sizing-scale-4);
          fill: var(--brand-red);
          transform: translateY(2px);
          
          .background {
            fill: white;
          }
        }
        
        a:hover {
          .icon {
            fill: var(--brand-purple);
          }
        }
      }
    }
  }
  
  &__view-all-products {
    margin: var(--sizing-scale-4);
    text-align: center;
  }
}