@import "mixins";

html {
  scroll-behavior: smooth;
}

a {
  color: var(--brand-red);
  text-decoration: none;
  
  &:hover {
    color: var(--brand-purple);
  }
}

hr {
  background: var(--color, var(--brand-red));
  border: none;
  height: 2px;
  margin: 0;
  
  &.small {
    width: var(--sizing-scale-14);
    margin: auto;
    
    @include for-phone {
      width: 70vw;
    }
  }
  
  &.secondary {
    background: var(--brand-purple);
  }
}

a.anchor {
  display: block;
  position: relative;
  visibility: hidden;
  top: calc(var(--header-height) * -1);
  
  @include for-phone {
    top: calc(var(--header-height-mobile) * -1);;
  }
}

.top-level-page {
  max-width: var(--sizing-scale-19);
  margin: auto;
  margin-top: var(--sizing-scale-2);  
  padding: var(--sizing-scale-3);
}