:root {
  --brand-red: hsl(358, 78.6%, 50.6%);
  --brand-purple: hsl(315, 48.8%, 47.5%);
  --brand-orange: hsl(26, 91%, 56.3%);
  --brand-green: hsl(90, 56%, 45.5%);
  
  --gray-1: hsl(216, 33%, 97%);
  --gray-2: hsl(214, 15%, 91%);
  
  --yellow: hsl(42, 78%, 60%);
}