.product {
  height: var(--sizing-scale-15);
  max-width: var(--sizing-scale-14);
  background: var(--gray-1);
  border-radius: 10px;
  padding: var(--sizing-scale-3);
  color: black;
  text-align: center;
  transition: all 0.1s ease-in;
  cursor: pointer;
  position: relative;
  
  &__name {
    text-decoration: underline;
    text-decoration-thickness: 3px;
  }
  
  &__thumbnail {    
    img {
      height: var(--sizing-scale-13);
    }
  }
  
  &__caption {
    font-size: var(--text-scale-5);
    font-weight: 600;
    line-height: 1.1;
  }
  
  &__capacity {
    text-align: left;
    position: absolute;
    left: var(--sizing-scale-3);
    right: var(--sizing-scale-3);
    bottom: var(--sizing-scale-4);
    font-size: var(--text-scale-4);
    font-weight: 300;
    
    p:first-child {
      font-size: var(--text-scale-3);
      font-weight: 200;
    }
    
    em {
      font-weight: 600;
    }
  }
  
  &__details-link {
    .icon {
      width: var(--sizing-scale-4);
      height: var(--sizing-scale-4);
      fill: var(--brand-red);
      transform: translateY(2px);
      
      .background {
        fill: white;
      }
    }
  }
  
  &:hover {
    background: var(--hover-color);
    transform: translateY(-5px);
    color: white;
  }
}