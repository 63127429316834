@mixin for-phone-small {
  @media (max-width: 320px) { @content; }
}
@mixin for-phone {
  @media (max-width: 600px) { @content; }
}
@mixin for-tablet-portrait {
  @media (max-width: 900px) { @content; }
}
@mixin for-tablet-landscape {
  @media (max-width: 1200px) { @content; }
}
@mixin for-desktop {
  @media (max-width: 1799px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}
