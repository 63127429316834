@font-face {
    font-family: 'Jost';
    src: local('Jost Black'), local('Jost-Black'),
        url('Jost-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Black Italic'), local('Jost-BlackItalic'),
        url('Jost-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Bold'), local('Jost-Bold'),
        url('Jost-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Bold Italic'), local('Jost-BoldItalic'),
        url('Jost-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost ExtraBold'), local('Jost-ExtraBold'),
        url('Jost-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost ExtraBold Italic'), local('Jost-ExtraBoldItalic'),
        url('Jost-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost ExtraLight'), local('Jost-ExtraLight'),
        url('Jost-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Light'), local('Jost-Light'),
        url('Jost-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Italic'), local('Jost-Italic'),
        url('Jost-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost ExtraLight Italic'), local('Jost-ExtraLightItalic'),
        url('Jost-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Medium'), local('Jost-Medium'),
        url('Jost-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Light Italic'), local('Jost-LightItalic'),
        url('Jost-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Medium Italic'), local('Jost-MediumItalic'),
        url('Jost-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost SemiBold'), local('Jost-SemiBold'),
        url('Jost-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Regular'), local('Jost-Regular'),
        url('Jost-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost SemiBold Italic'), local('Jost-SemiBoldItalic'),
        url('Jost-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Thin'), local('Jost-Thin'),
        url('Jost-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: local('Jost Thin Italic'), local('Jost-ThinItalic'),
        url('Jost-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

