@import "mixins";

footer {
  background: var(--gray-1);
  
  p {
    margin: var(--sizing-scale-2);
    font-size: var(--text-scale-3);
  }
  
  .footer__attribution {
    text-align: right;
    flex: 1 0 auto;
  }
  
  @include for-tablet-portrait {
    .footer__attribution, p {
      margin: var(--sizing-scale-1);
      text-align: center;
      flex: inherit;
    }
  }
}