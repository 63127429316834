@import "mixins";

header {
  width: 100%;
  background: white;
  height: var(--header-height);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  border-bottom: 2px solid var(--yellow);
  
  &.mobile {
    display: none;
  }
  
  .logo {
    height: calc(var(--header-height) * 0.60);
    margin: auto;
    margin-top: var(--sizing-scale-1);
    width: auto;
    
    @include for-tablet-portrait {
      max-height: calc(var(--header-height) * 0.70);
      height: 8vw;
    }
  }
  
  > div {
    display: flex;
    width: 94%;
    align-items: center;
    justify-content: center;
    
    a {
      font-family: var(--sans-serif-secondary);
    }
    
    .header__contact {
      width: 35%;
      font-size: var(--text-scale-5);  
      
      .icon {
        height: var(--sizing-scale-5);
        fill: var(--brand-purple);
        
        .background {
          stroke: white;
        }
      }
      
      a {
        color: black;
        
        &:hover {
          color: var(--brand-purple);
        }
      }
      
      @include for-tablet-portrait {
        font-size: 2.4vw;
        
        .icon {
          height: 2.8vw;
        }
        
        > * > * ~ * {
          margin-left: 1vw;
        }
      }
    }
    
    nav {
      width: 35%;
      text-align: right;
      font-size: var(--text-scale-6);
      font-weight: 200;
      color: black;
      
      li {
        display: inline-block;
      }
      
      a {
        color: var(--brand-red);        
        font-family: var(--sans-serif-secondary);
        
        &:hover {
          color: inherit;
          border-bottom: 3px solid var(--brand-purple);
        }
      }
      
      ul > * ~ * {
        margin-left: var(--sizing-scale-5);
      }
      
      @include for-tablet-portrait {
        font-size: 2.8vw;
      
        ul > * ~ * {
          margin-left: 1.2vw;
        }
      }
    }
  }
  
  @include for-phone {
    display: none;
           
    &.mobile {
      display: block;
      width: 100%;
      overflow: visible;
      height: auto;
      border-bottom: none;
      --collapse-transition: all 0.1s ease-in;
      
      .logo {
        display: block;
        margin: auto;
        height: calc(var(--header-height-mobile) * 0.3);
        margin-top: var(--sizing-scale-3);
        margin-bottom: var(--sizing-scale-3);
        transition: var(--collapse-transition);
      }
      
      .header__contact {
        width: 100%;
        background: var(--brand-purple);
        height: calc(var(--header-height-mobile) * 0.21);
        color: white;
        transition: var(--collapse-transition);
        font-size: var(--text-scale-3);
        justify-content: space-evenly;
        
        @include for-phone-small {
          font-size: var(--text-scale-2);
        }
        
        .icon {
          height: var(--sizing-scale-4);
          fill: white;
          
          .background {
            stroke: var(--brand-purple);
          }
        }
        
        a {
          color: white;
        
          &:hover {
            color: inherit;
          }
        }
      }
      
      &.collapse {
        .header__contact {
          height: 0;
        }
        
        .logo {
          height: var(--sizing-scale-6);
        }
      }
    }
    
    nav {
      background: white;
      font-size: var(--text-scale-5);
      font-weight: 300;
      font-family: var(--sans-serif-primary);
    }
  }
}