@import "mixins";

@mixin vertical-gaps {
  &.flex--gap-s {
    > * ~ * {
      margin-top: var(--gap-s);
      margin-left: 0;
    }
  }
  
  &.flex--gap-m {
    > * ~ * {
      margin-top: var(--gap-m);
      margin-left: 0;
    }
  }
  
  &.flex--gap-l {
    > * ~ * {
      margin-top: var(--gap-l);
      margin-left: 0;
    }
  }
  
  &.flex--gap-xl {
    > * ~ * {
      margin-top: var(--gap-xl);
      margin-left: 0;
    }
  }
  
  &.flex--gap-xxl {
    > * ~ * {
      margin-top: var(--gap-xxl);
      margin-left: 0;
    }
  }
}

.flex {
  --gap-s: var(--sizing-scale-1);
  --gap-m: var(--sizing-scale-3);
  --gap-l: var(--sizing-scale-5);
  --gap-xl: var(--sizing-scale-7);
  --gap-xxl: var(--sizing-scale-9);
  
  display: flex;
    
  &--align-center {
    align-items: center;
  }
  
  &--center {
    align-items: center;
    justify-content: center;
  }
  
  &--wrap {
    flex-wrap: wrap;
  }
  
  &--gap-s {
    > * ~ * {
      margin-left: var(--gap-s);
    }
  }
  
  &--gap-m {
    > * ~ * {
      margin-left: var(--gap-m);
    }
  }
  
  &--gap-l {
    > * ~ * {
      margin-left: var(--gap-l);
    }
  }
  
  &--gap-xl {
    > * ~ * {
      margin-left: var(--gap-xl);
    }
  }
  
  &--gap-xxl {
    > * ~ * {
      margin-left: var(--gap-xxl);
    }
  }
  
  
  &--collapse-med {
    @include for-tablet-portrait {
      flex-direction: column;
      
      @include vertical-gaps;
    }
  }
  
  &--collapse-sml {
    @include for-phone {
      flex-direction: column;
      
      @include vertical-gaps;
    }
  }
  
  &--vertical {
    flex-direction: column;
    
    @include vertical-gaps;
  }
}